<template>
    <content-loader :height="160" secondaryColor="#ccd8ff" primaryColor="#e7ecff">
        <rect x="5" y="5" rx="3" ry="3" width="90" height="15" />
        <rect x="5" y="25" rx="3" ry="3" width="90" height="15" />
        <rect x="5" y="45" rx="3" ry="3" width="90" height="15" />
        <rect x="5" y="65" rx="3" ry="3" width="90" height="15" />
        <rect x="5" y="85" rx="3" ry="3" width="90" height="15" />
        <rect x="5" y="105" rx="3" ry="3" width="90" height="15" />
        <rect x="105" y="5" rx="4" ry="3" width="90" height="35" />
        <rect x="205" y="5" rx="4" ry="3" width="90" height="35" />
        <rect x="305" y="5" rx="4" ry="3" width="90" height="35" />
        <rect x="105" y="45" rx="4" ry="3" width="90" height="35" />
        <rect x="205" y="45" rx="4" ry="3" width="90" height="35" />
        <rect x="305" y="45" rx="4" ry="3" width="90" height="35" />
        <rect x="105" y="85" rx="4" ry="3" width="90" height="35" />
        <rect x="205" y="85" rx="4" ry="3" width="90" height="35" />
        <rect x="305" y="85" rx="4" ry="3" width="90" height="35" />

    </content-loader>
</template>

<script>
    import { ContentLoader } from 'vue-content-loader';
    export default {
        components : {
            ContentLoader
        }
    }
</script>
